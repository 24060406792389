<template>
  <dl
    class="product-details-tab tab-panel"
    id="product-details-tab"
    :class="isTabOpen ? 'tab-open' : 'tab-closed'"
  >
    <dt
      class="tab-trigger"
      aria-labelledby="product-details-h4"
      tabindex="0"
      role="button"
      @keydown.enter="
        toggleTab();
        readMore = false;
      "
      @click="
        toggleTab();
        readMore = false;
      "
    >
      <h4 id="product-details-h4">Product Details</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content" :class="readMore && isTabOpen ? 'read-open' : ''">
      <div v-html="product.description" style="white-space: pre-line" />

      <button
        v-if="displayedTextLength"
        tabindex="1"
        class="button button--full-width read-more-btn"
        @click="readMore = !readMore"
      >
        <span v-if="readMore">Read Less</span>
        <span v-else>Read More</span>
      </button>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductDelivery',
  components: {
    IconArrow,
  },
  computed: {
    ...mapState(useProductStore, ['product']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    displayedTextLength() {
      return this.product.description?.length >= 200;
    },

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_DETAILS';
    },
  },
  data: () => ({
    readMore: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_DETAILS') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_DETAILS';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
