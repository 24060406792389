<template>
  <div
    class="payment-popup-overlay overlay"
    :class="{
      'overlay--open': isPopupInEffect,
    }"
  >
    <div v-if="isPopupInEffect" class="payment-popup-overlay__content" @click="refocusPaymentPopup">
      <p>Can't see the payment window?</p>
      <p style="text-decoration: underline; cursor: pointer">Continue</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue';

import constants from '@/constants';
import { getCenteredPopupPosition } from '@/helpers/screen';
import { parseCartJwt } from '@/stores/CartStore';
import { getRouter } from '@/router';

import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';

import type { PropType } from 'vue';
import type { State as MainStoreState } from '@/stores/MainStore';
import { CartComplete, type CartJwtPayload } from '@/types/cart.types';

export default defineComponent({
  name: 'LaunchPaymentPopup',
  props: {
    // TODO maybe not using props would be better - DS
    paymentPopupState: {
      type: Object as PropType<MainStoreState['paymentPopupState']>,
      required: true,
    },
    paymentProviderToken: String, // only sent when is Braintree
    cartJwt: { type: String as PropType<CartJwtPayload>, required: true },
    paymentProvider: { type: String, required: true },
  },
  setup(props) {
    const mainStore = useMainStore();
    const cartStore = useCartStore();

    const popupPaymentWindow = ref<Window | null>(null);

    const launchPaymentPopup = () => {
      // TODO
      // - error handling
      // -- no JWT
      // -- window did not launch ok (should await ACK from popup)
      // - Handle when user refreshes payment solution page

      const popupDimensions = {
        width: 500,
        height: 650,
        offsetTop: 100,
      };

      const centeredPositionProps = getCenteredPopupPosition(
        popupDimensions.width,
        popupDimensions.height,
      );

      const url = `${window.document.location.origin}?${constants.PAYMENT_POPUP_WINDOW.isPaymentPopupLaunchIdentifier}=true`;

      popupPaymentWindow.value = window.open(
        url,
        constants.PAYMENT_POPUP_WINDOW.name,
        `
          width=${popupDimensions.width},
          height=${popupDimensions.height},
          left=${centeredPositionProps.left},
          top=${centeredPositionProps.top + popupDimensions.offsetTop},
          popup=true,
          location=false,
          toolbar=false,
          menubar=false
        `,
      );

      window.addEventListener('beforeunload', onUnloadTasks);

      if (popupPaymentWindow.value?.focus) {
        popupPaymentWindow.value.focus();
      }
    };

    const startPopupClosedChecker = () => {
      const pollInterval = 500;

      const pollTimer = setInterval(() => {
        if (!popupPaymentWindow.value || popupPaymentWindow.value.closed) {
          clearInterval(pollTimer);
          mainStore.onPaymentPopupClosed();
        }
      }, pollInterval);
    };

    const refocusPaymentPopup = () => {
      if (popupPaymentWindow.value) {
        popupPaymentWindow.value.focus();
      }
    };

    const handlePaymentPopupPostMessageEvent = async ({ detail }: { detail: ToDo }) => {
      const router = await getRouter();
      const { key, message } = detail;

      switch (key) {
        case 'PAYMENT_COMPLETE_RESPONSE':
          // TODO - TEMP use VueInstance instead of postMessage payloads
          cartStore.cart = new CartComplete(parseCartJwt(message.cartJwt!).cart);
          mainStore.redirectToView('/thankyou', router.replace);
          break;

        default:
          break;
      }
    };

    const isPopupInEffect = computed(
      () => ![null, 'IS_CLOSED'].includes(props.paymentPopupState?.launchState),
    );

    const onUnloadTasks = () => {
      // Standard practice but also helps avoid 'orphan' popup windows
      popupPaymentWindow.value?.close();

      window.removeEventListener(
        'paymentPopupPostMessageEvent',
        handlePaymentPopupPostMessageEvent,
      );
    };

    watch(
      () => props.paymentPopupState?.launchState,
      (newVal) => {
        switch (newVal) {
          case 'IS_LAUNCHING':
            launchPaymentPopup();
            startPopupClosedChecker();
            break;

          case 'IS_CLOSED':
            if (popupPaymentWindow.value) {
              popupPaymentWindow.value.close();
              popupPaymentWindow.value = null;
            }
            break;

          default:
            break;
        }
      },
    );

    onMounted(() => {
      window.addEventListener('paymentPopupPostMessageEvent', handlePaymentPopupPostMessageEvent);
    });

    onUnmounted(() => {
      onUnloadTasks();
    });

    return {
      isPopupInEffect,
      refocusPaymentPopup,
    };
  },
});
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
