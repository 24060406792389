<template>
  <header class="header-container side-position" v-if="logo">
    <div :style="`background: ${ primaryColor }`">
      <span>Sold and Dispatched from</span>
      <Logo />
    </div>
    <div :style="`background: ${ primaryColor }`" class="hidden-header-part"></div>
  </header>
</template>

<script>
import { mapState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import Logo from '@/components/Logo/index.vue';

export default {
  name: 'Footer',
  components: {
    Logo,
  },
  computed: {
    ...mapState(useAdvertiserStore, ['companyName', 'primaryColor', 'logo']),
  },
};
</script>


<style lang="scss" scoped>
@import './styles.scss';
</style>