<template>
  <img tabindex="0" class="logo" :src="logo" :alt="`${ companyName } Logo`" />
</template>

<script>
// Stores
import { mapState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

export default {
  name: 'Logo',
  computed: {
    ...mapState(useAdvertiserStore, ['logo', 'companyName']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
