<template>
  <div
    :class="{
      prices: true,
      'skeleton-loader': isQuantityChangeInProgress,
    }"
  >
    <!-- Discount amount -->
    <span class="price">
      <span
        v-if="cart?.pricing?.totalDiscountPercentFromOriginal"
        data-test="discount-price"
        v-text="`-${Math.round(cart?.pricing?.totalDiscountPercentFromOriginal)}%`"
        class="mr-10"
      ></span>
    </span>

    <!-- Total price -->
    <span class="price">
      <span data-test="total-price">
        <span class="superscript" v-text="totalPriceSplit.currency" />
        <span v-text="totalPriceSplit.whole" />
        <span class="superscript" v-text="totalPriceSplit.remainder" />
      </span>
    </span>

    <!-- RRP price -->
    <span
      v-if="getSelectedProductOriginalPrice"
      class="price"
      data-test="rrp-price"
      v-text="`RRP ${getFormattedPrice(getSelectedProductOriginalPrice)}`"
    ></span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCartStore } from '@/stores/CartStore';
import ProductQty from '@/components/ProductQty/index.vue';
import TextFormat from '@/helpers/textFormat';

export default {
  name: 'PricesPilot1',
  components: {
    ProductQty,
  },
  computed: {
    ...mapState(useCartStore, [
      'cart',
      'getFormattedPrice',
      'getTotalCartPrice',
      'isQuantityChangeInProgress',
      'getSelectedProductOriginalPrice',
    ]),

    totalPriceSplit() {
      return TextFormat.splitFormattedPrice(this.getFormattedPrice(this.getTotalCartPrice));
    },
  },
  methods: {
    ...mapActions(useCartStore, ['incrementQuantity', 'decrementQuantity', 'setQuantity']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
